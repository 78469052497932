import { Controller } from "@hotwired/stimulus";
import Dropzone from "../utils/dropzone";
import { showCustomAlert } from "./modal_controller";

export default class extends Controller {
  connect() {
    this.dropzone = new Dropzone(this.element);
    this.dropzoneInput = this.dropzone.element.querySelector('.dropzone-input');
    this.uploadDocument = this.dropzone.element.querySelector('.upload-document');
    this.inputFiles = [];

    this.dropzoneInput.addEventListener('input', event => {
      event.preventDefault();
      event.stopPropagation();
      this.addAttachments(event.currentTarget.files);
    }, false);

    const defaultDropAction = this.dropzone.element.ondrop;
    this.dropzone.element.ondrop = (e) => {
      if (typeof defaultDropAction === 'function') {
        defaultDropAction(e);
      }
      this.addAttachments(e.dataTransfer.files);
    };
  }

  addAttachments(newFiles) {
    const allowedExtensions = this.dropzoneInput.dataset.allowedextensions
    const isMultiple = this.dropzoneInput.getAttribute('multiple');

    for (const file of newFiles) {
      const extension = file.name.split('.').pop().toLowerCase();

      // Verificar si el archivo tiene una extensión permitida
      if (allowedExtensions.includes('.' + extension)) {
        // Verificar si el archivo ya existe en this.inputFiles
        const fileExists = this.inputFiles.some(existingFile => existingFile.name === file.name);

        if (!fileExists && (isMultiple || this.inputFiles.length === 0)) {
          this.inputFiles.push(file);
          this.dropzone.element.appendChild(this.createDocumentTemplate(file));
          this.uploadDocument.classList.add('hide');

          // Leer el número de líneas del archivo
          this.readLines(file);
        }
      } else {
        //erase the file from the input
        this.dropzoneInput.value = '';
        showCustomAlert(`El formato del archivo no es válido. Por favor, carga un archivo con extension: ${allowedExtensions}`);
        return false;
      }
    }

    this.updateInputFiles();
  }

  readLines(file) {
    const reader = new FileReader();
    const hiddenInput = this.dropzone.element.querySelector('#linesCountInput');
  
    reader.onload = (event) => {
      const content = event.target.result;
      const lineCount = content.split(/\r\n|\r|\n/).length;
  
      hiddenInput.value = lineCount;
    };
  
    reader.readAsText(file);
  }

  createDocumentTemplate(file) {
    const documentTemplate = document.createElement('div');
    documentTemplate.innerHTML = `
      <div class="hoverable document-card">
        <p class="name">${file.name}</p>
        <div class="btn btn-remove-file waves-effect waves-light"></div>
      </div>
    `;

    const removeButton = documentTemplate.querySelector('.btn-remove-file');
    removeButton.addEventListener('click', (event) => {
      event.preventDefault();
      event.stopPropagation();
      documentTemplate.remove();
      this.removeInputFile(file);
    });

    return documentTemplate;
  }

  removeInputFile(fileToRemove) {
    // Eliminar el archivo de this.inputFiles
    this.inputFiles = this.inputFiles.filter(file => file.name !== fileToRemove.name);

    // Actualizar los archivos en el input
    this.uploadDocument.classList.remove('hide')
    this.updateInputFiles();
  }

  updateInputFiles() {
    // Crear un nuevo FileList a partir de this.inputFiles
    const newFileList = new DataTransfer();
    this.inputFiles.forEach(file => newFileList.items.add(file));

    // Asignar el nuevo FileList al input
    this.dropzoneInput.files = newFileList.files;
    //emit event change
    this.dropzoneInput.form.dispatchEvent(new Event('change'));
  }
}
