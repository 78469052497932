import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.showHideFormulario();

    // Event listener for file input changes
    this.element.addEventListener('change', (event) => {
      if (event.target.type === 'file') {
        this.handleFileChange(event.target);
      }
      else if (event.target.type === 'checkbox') {
        this.handleCheckboxChange(event.target);
      }
      else{
        this.validate(event.target)
      }
    });
    
    // Event listener for form submissions
    // this.element.addEventListener('submit', (event) => {
    //   event.preventDefault();

    //   const form = event.target.closest('form');
    //   if (this.validate(form)) {
    //     form.submit();
    //   }
    // });
  }

  handleFileChange(input) {
    const form = input.closest('form');
    const submitButton = form.querySelector('button[type="submit"]');

    // Show the submit button if there is at least one file selected
    if (input.files.length > 0) {
      submitButton.classList.remove('hide');
    } else {
      submitButton.classList.add('hide');
    }
  }

  handleCheckboxChange(input) {
    const form = input.closest('form');
    const submitButton = form.querySelector('button[type="submit"]');
    const documentFileInput = form.querySelector('.dropzone');

    // Show the submit button if checkbox checked
    if (input.checked) {
      submitButton.classList.remove('hide');
      documentFileInput.classList.add('hide');
    } else {
      documentFileInput.classList.remove('hide');
      submitButton.classList.add('hide');
    }
  }

  validate(form) {
    let hasAttachments = false;
    const fileInputs = form.querySelectorAll('.dropzone-input');

    fileInputs.forEach((input) => {
      if (input.files.length > 0) {
        hasAttachments = true;
      }
    });

    // Show or hide the submit button based on whether files are attached
    const submitButton = form.querySelector('button[type="submit"]');
    
    if (hasAttachments) {
      submitButton.classList.remove('hide');
      return true;
    }
    else {
      submitButton.classList.add('hide');
      return false;
    }
  }

  showHideFormulario() {
    const complaintTypeSelect = document.getElementById("complaint_type");
    const formTypes = document.querySelectorAll('.col-reports');

    if (complaintTypeSelect) {
      const selectedComplaintType = complaintTypeSelect.value;
      formTypes.forEach(formType => {
        formType.classList.toggle("hide", !formType.id.startsWith(selectedComplaintType));
      });

      complaintTypeSelect.addEventListener("change", () => {
        const selectedComplaintType = complaintTypeSelect.value;
        formTypes.forEach(formType => {
          formType.classList.toggle("hide", !formType.id.startsWith(selectedComplaintType));
        });
      });
    }
  }
}
