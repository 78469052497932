import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.modal = M.Modal.init(this.element);
    this.openModal();

    Turbo.setConfirmMethod(() => {
      let dialog = document.getElementById('turbo-confirm')
      dialog.M_Modal.open();

      // Configuramos el evento click para el botón de confirmación
      return new Promise((resolve) => {
        document.getElementById('confirmButton').addEventListener('click', function(event) {
          resolve(event.target.value === 'confirm');  // Resolvemos si es 'confirm'
        }, { once: true });
      })
      .then((result) => {
        return result
      });
    });
  }

  openModal() {
    this.modal.open();
  }

  closeModal() {
    this.modal.close();
  }
}

export function showCustomAlert(message) {
  const modal = document.createElement('div');
  modal.id = 'alertModal';
  modal.classList.add('modal');
  modal.innerHTML = `
    <div class="modal-content">
      <div class="modal-body">
        <h4 class="error">${message}</h4>
      </div>
    </div>
  `;

  document.body.appendChild(modal);

  // Initialize the modal using Materialize CSS
  const instance = M.Modal.init(modal);
  instance.open();

  // Close the modal after 3 seconds
  setTimeout(() => {
    instance.close();
  }, 2000);
}
