// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "../channels"
import "../controllers"
import { Turbo } from "@hotwired/turbo-rails"

Turbo.start()
Rails.start()
ActiveStorage.start()
import 'materialize-css/dist/js/materialize';

document.addEventListener('DOMContentLoaded', function() {
  // Inicializa los componentes de Materialize
  M.AutoInit();
});

document.addEventListener('turbo:load', () => {
  Rails.refreshCSRFTokens();
})